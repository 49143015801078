import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import React from "react";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "dashboard.landing-images",
    path: `${APP_PREFIX_PATH}/dashboards/landing-images`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/landing-image")
    ),
  },
  {
    key: "dashboard.blog",
    path: `${APP_PREFIX_PATH}/dashboards/blog`,
    component: React.lazy(() => import("views/app-views/dashboards/blog")),
  },
  {
    key: "dashboard.blog.edit",
    path: `${APP_PREFIX_PATH}/dashboards/blog/edit/:id`,
    component: React.lazy(() => import("views/app-views/dashboards/blog")),
  },
  {
    key: "dashboard.categoryqa",
    path: `${APP_PREFIX_PATH}/dashboards/category-qa`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/categoryqa")
    ),
  },
  {
    key: "dashboard.new_product",
    path: `${APP_PREFIX_PATH}/dashboards/new-product`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/new-product")
    ),
  },
  {
    key: "dashboard.categoryqa.edit",
    path: `${APP_PREFIX_PATH}/dashboards/category-qa/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/categoryqa")
    ),
  },
  {
    key: "dashboard.vendorbrandcategory",
    path: `${APP_PREFIX_PATH}/dashboards/vendor-brand-category`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/vendor-brand-category")
    ),
  },
  {
    key: "listings.products",
    path: `${APP_PREFIX_PATH}/listings/products`,
    component: React.lazy(() => import("views/app-views/listings/products")),
  },
  {
    key: "listings.coupon-codes",
    path: `${APP_PREFIX_PATH}/listings/coupon-codes`,
    component: React.lazy(() => import("views/app-views/listings/coupon")),
  },
  {
    key: "listings.products.old",
    path: `${APP_PREFIX_PATH}/listings/products-old`,
    component: React.lazy(() =>
      import("views/app-views/listings/products/old-index")
    ),
  },
  {
    key: "listings.categories",
    path: `${APP_PREFIX_PATH}/listings/categories`,
    component: React.lazy(() => import("views/app-views/listings/categories")),
  },
  {
    key: "listings.blogs",
    path: `${APP_PREFIX_PATH}/listings/blogs`,
    component: React.lazy(() => import("views/app-views/listings/blog")),
  },
  {
    key: "listings.inspiration",
    path: `${APP_PREFIX_PATH}/listings/inspiration`,
    component: React.lazy(() => import("views/app-views/listings/appl-image")),
  },
  {
    key: "listings.delivery-visx",
    path: `${APP_PREFIX_PATH}/listings/delivery-visx`,
    component: React.lazy(() =>
      import("views/app-views/listings/delivery-visx")
    ),
  },
  {
    key: "listings.brands",
    path: `${APP_PREFIX_PATH}/listings/brands`,
    component: React.lazy(() => import("views/app-views/listings/brands")),
  },
  {
    key: "listings.product-upload",
    path: `${APP_PREFIX_PATH}/listings/product-upload`,
    component: React.lazy(() =>
      import("views/app-views/listings/product-upload")
    ),
  },
  {
    key: "listings.product-edit",
    path: `${APP_PREFIX_PATH}/listings/product-edit/`,
    component: React.lazy(() =>
      import("views/app-views/listings/product-edit")
    ),
  },
  {
    key: "listings.product-edit-old",
    path: `${APP_PREFIX_PATH}/listings/product-edit-old/:productHandle`,
    component: React.lazy(() =>
      import("views/app-views/listings/product-edit/index-old")
    ),
  },
  {
    key: "listings.images",
    path: `${APP_PREFIX_PATH}/listings/images`,
    component: React.lazy(() => import("views/app-views/listings/images")),
  },
  {
    key: "listings.images",
    path: `${APP_PREFIX_PATH}/listings/color-fam`,
    component: React.lazy(() => import("views/app-views/listings/color-fam")),
  },
];
