import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { notification } from 'antd';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
})

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
	
	if (jwtToken) {
		config.headers.authorization = `JWT ${jwtToken}`
	}

  	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)
})

export default service