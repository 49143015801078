import { API_BASE_URL } from "configs/AppConfig";

export const verifyOtp = async (data) => {
    const response = await fetch(API_BASE_URL + '/verify-otp?country_code=91&contact=' + data.contact + '&otp=' + data.otp)
    return response.json()
};

export const sendOtp = async (data) => {
    const response = await fetch(API_BASE_URL + '/login-otp?country_code=91&contact=' + data.contact)
    return response.text()
}