import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.verifyOtp = async (data) => {
	const response = await fetch({
		url: '/verify-otp?country_code=91&contact=' + data.contact + '&otp=' + data.otp,
		method: 'get',
	})
	return response
}

AuthService.sendOtp = function (data) {
	return fetch({
		url: '/login-otp?country_code=91&contact=' + data.contact,
		method: 'get',
	})
}

AuthService.register = function (data) {
	return fetch({
		url: '/auth/register',
		method: 'post',
		data: data
	})
}

AuthService.logout = function () {
	return fetch({
		url: '/auth/logout',
		method: 'post'
	})
}

AuthService.loginInOAuth = function () {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post'
	})
}

export default AuthService;